<template>
  <span v-if="props.phoneNumber" class="flex-inline">
    <span
      :class="{
        'cursor-pointer text-primary-600': props.canDial
      }"
      class="flex-inline items-center"
      @click="onClick"
    >
      {{ phoneNumberFlag(props.phoneNumber) }}
      {{ formatPhoneNumber(props.phoneNumber) }}
    </span>
    <Lookup
      v-if="lookup"
      :fields="lookup"
      :phoneNumber="props.phoneNumber"
    />
  </span>
</template>

<script lang="ts" setup>
const emit = defineEmits<{
  click: [event: Event]
}>()
const props = withDefaults(defineProps<{
  canDial?: boolean,
  phoneNumber?: string|null
  lookup?: LookupField[]
}>(), {
  canDial: false
})

const onClick = (event: Event) => {
  if (!props.phoneNumber) return

  if (props.canDial) {
    const { quickCall } = useDialer()
    quickCall.value = {to: props.phoneNumber}
  } {
    emit('click', event)
  }
}
</script>
