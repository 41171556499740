<template>
  <a class="cursor-pointer ml-2" href="#"  @click="toggle">
    <i class="pi pi-info-circle" />
  </a>

  <Popover ref="op">
    <div v-if="objectList" class="w-60">
      <div v-for="field in Object.keys(resolvedFields)" class="space-y-5 text-sm">
        <DetailBox v-if="field == 'caller_name'" :data="resolvedFields[field]">
          <DetailRow field="caller_name" header="Name" />
          <DetailRow field="caller_type" header="Type" />
        </DetailBox>

        <DetailBox v-if="field == 'line_type_intelligence'" :data="resolvedFields[field]">
          <DetailRow field="carrier_name" header="Carrier" />
          <DetailRow field="type" header="Type" />
        </DetailBox>
      </div>

      <div v-if="unresolvedFields.length" class="text-center">
        <p class="mb-3">You can request all or one detail(s) of this phone number.</p>

        <Button
          v-if="unresolvedFields.length === 1"
          :label="`Request ${fieldLabels[unresolvedFields[0]]}`"
          icon="pi pi-download"
          size="small"
          @click="() => request(unresolvedFields)"
        />

        <SplitButton
          v-else
          :model="unresolvedFields.map(field => ({
            label: `Request ${fieldLabels[field]}`,
            command: () => request([field])
          }))"
          icon="pi pi-download"
          label="Request all"
          size="small"
          @click="() => request(unresolvedFields)"
        />
      </div>
    </div>

    <div v-else class="py-5 text-center">
      <ProgressSpinner stroke-width="8" style="height:40px; width: 40px;" />
    </div>
  </Popover>
</template>

<script lang="ts" setup>
import Popover from 'primevue/popover'

const { $api } = useNuxtApp()
const loading = ref<boolean>(false)
const objectList = ref<Paginated<Lookup>>()
const op = ref<typeof Popover>()
const props = defineProps<{
  phoneNumber: string,
  fields: LookupField[]
}>()

declare type ResolvedField = Record<
  LookupField,
  LookupCallerName|LookupLineTypeIntelligence|LookupLineStatus|null
>

const fieldLabels = <Record<LookupField, string>>{
  caller_name: 'caller',
  line_type_intelligence: 'line carrier',
  line_status: 'line status'
}

const resolvedFields = computed<ResolvedField>(() => {
  const result = <ResolvedField>{}

  if (!objectList.value || !objectList.value.count) return result

  const fields = props.fields.map(field => {
    const item = objectList.value?.results.find(item => !!item[field])
    return { field, value: item ? item[field] : null }
  })

  fields.forEach(({field, value}) => {
    if (!value) return
    result[field] = value
  })
  return result
})

const unresolvedFields = computed<LookupField[]>(() => {
  const allFields = <LookupField[]>['caller_name', 'line_type_intelligence']
  const fields = Object.keys(resolvedFields.value)
  return allFields.filter(x => !fields.includes(x));
})

const load = async () => {
  loading.value = true

  await $api<Paginated<Lookup>>('/crm/lookups/', {
    params: {
      phone_number: props.phoneNumber,
      fields: props.fields,
    }
  })
    .then(response => objectList.value = response)
    .finally(() => loading.value = false)
}

const toggle = (event: Event) => {
  if (!op.value) return
  op.value.toggle(event)
  load()
}

const request = async (fields: LookupField[]) => {
  loading.value = true

  await $api('/crm/lookups/', {
    method: 'POST',
    body: JSON.stringify({
      phone_number: props.phoneNumber,
      fields,
    }),
  })
    .then(async () => await load())
    .finally(() => loading.value = false)
}
</script>
